import React from 'react';
import { Link } from "gatsby"
// import ServiceImage from '../../images/service-image.png'

const HomeServices = ({pageContent}) => (
    <div className="pb-5 mb-4 mb-md-5" id='services'>
        <div className="container py-120">
            <div className="row justify-content-center text-center">
                <div className="col-md-10 pt-4 col-lg-8 col-xxl-9">
                    <h2 className='h1' data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: pageContent?.serviceSectionSubTitle}} />
                    <p  data-aos="fade-up" data-aos-delay="400">{pageContent?.serviceSectionText}</p>
                </div>
            </div>
        </div>
        
        <div className="services-list-ads mb-120 bg-primary mt-3 py-120 pb-0 pb-3">
            <div className="container container-md">
                {pageContent?.adPageServices?.map((service, index) => (
                    <div className='row mb-5 pb-md-5 pb-3 gx-0 align-items-center' key={index}>
                        <div className={`col-md-6 mb-md-0 mb-4 ${index % 2 ? 'order-md-2' : ''}`}  data-aos="fade-up" data-aos-delay="200">
                            <picture>
                                <source media="(min-width:575px)" srcSet={service.image?.mediaItemUrl} />
                                <img loading="lazy" width="396" height="278" src={service.image?.mediaItemUrl} alt={service.image?.altText} style={{borderRadius : '20px'}} className="w-100 gatsby-image-wrapper gatsby-image-wrapper-main-blog img-fluid " />
                            </picture>
                        </div>
                        <div className='col-md-6' data-aos="fade-up" data-aos-delay="200">
                            <div className={index % 2 ? 'pe-md-5' : 'ps-md-5'}>
                                <h3 dangerouslySetInnerHTML={{__html: service?.heading}} />
                                <div className='price'><small>{service?.startingFrom}</small></div>
                                <p>{service?.serviceText}</p>
                                <Link to="/register#contact" className='btn d-inline-flex btn-lg py-sm-2 ps-sm-4 align-items-center btn-control text-uppercase'>
                                    {service?.buttonText} 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi ms-3 bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </div>
);

export default HomeServices;