import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

function FAQ({pageContent}) {
  return (
    <Accordion defaultActiveKey="0">
        {pageContent?.map((faq, index) => (
        <Accordion.Item eventKey={index} key={index}>
            <Accordion.Header>{faq?.question}</Accordion.Header>
            <Accordion.Body>{faq?.answer}</Accordion.Body>
        </Accordion.Item>
        ))}
      
    </Accordion>
  );
}

export default FAQ;