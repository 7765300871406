import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Logo from "../logoAnimation";
import HeaderQuery from "./headerNav";

const Header = ({ HeadClass, siteTitle }) => {
  const [show, setShow] = React.useState(false);
  const [scroll, setScroll] = React.useState(false);
  const handle = () => setShow(!show);
  const handleLink = () => setShow(false);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10)
    })
  }, [])
  
  
  return(
      <header className={`header w-100 ${scroll ? 'sticky'  : HeadClass} ${show ? 'active' : ''}`}>
        <div className="header-view">
          <div className="container-fluid p-0 d-flex align-items-center justify-content-between">
            <div className="header-left">
              <button aria-label="menu" onClick={() => handle()} className={`btn btn-reset header-menu ${show ? 'active' : ''}`}>
                <span></span>
                <span></span>
              </button>
            </div>
            {/* <ul className="header-nav-view mb-0 list-unstyled d-none d-md-flex align-items-center">
              <li><Link to="/register#home">Home</Link></li>
              <li><Link to="/register#services">Services</Link></li>
            </ul> */}
            <div className="header-logo">
              <Link to="/" aria-label={siteTitle} onClick={() => handleLink()} title={siteTitle}>
                <Logo />  
              </Link>
            </div>
            {/* <ul className="header-nav-view mb-0 list-unstyled d-none d-md-flex align-items-center">
              <li><Link to="/register#partner">Partner</Link></li>
              <li><Link to="/register#faq">FAQ’s</Link></li>
            </ul> */}
            <Link aria-label="Work with us" to="/register#contact" onClick={() => handleLink()}  className="btn btn-link">WORK WITH US</Link>
          </div>
        </div>

        <HeaderQuery barLine={handleLink} />
      </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header