import React, { Component } from 'react'
import { Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2';

export default class contactForm extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            phone: '',
            phoneError: '',
            success: false,
            stepCount: 1,
            fields: {},
            errors: {},
            services: [],
            loading: false
        };

        this.contactSubmit = this.contactSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
      }

      handleStep = (count) => {
        this.setState({
            stepCount: count
        });
      }

      handleServiceSelect  = (event)  => {
        var dayArr = [...this.state.services];
        const value = event.target.value
        const index = dayArr.findIndex(day => day === value);
        if(index > -1) {
            dayArr = [...dayArr.slice(0, index), ...dayArr.slice(index + 1)]
        } else {
            dayArr.push(value);
        }
        this.setState({services: dayArr});
     }

      handleRedo = () =>{
        this.setState({
            success: false,
            stepCount: 1,
            phone: '',
            services: [],
            fields:{
                user_name: '',
                email: '',
                organization: '',
                description: ''
            }
        });
      }

      contactSubmit = e => {
        e.preventDefault();
        // console.log(this.state.services)

        if(this.validateForm()) {
            this.setState({
                loading: true
            });

            setTimeout(() => {
                this.setState({
                    success: true,
                    loading: false,

                    phone: '',
                    services: [],
                    fields:{
                        user_name: '',
                        email: '',
                        organization: ''
                    }
                });

                // this.handleServiceSelect();
            }, 1000);

            var formdata = new FormData();
            formdata.append("user_name", this.state.fields.user_name);
            formdata.append("email", this.state.fields.email);
            formdata.append("phone", this.state.phone);
            formdata.append("organization", this.state.fields.organization);
            formdata.append("services", this.state.services);

            var requestOptions = {
                method: 'POST',
                body: formdata,
                redirect: 'follow'
            };

            fetch("https://dev.alkye.com/get-a-quote-form/", requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                // this.setState({
                //     success: true,
                //     loading: false
                // });
            })
            .catch(error => console.log('error', error));
        }
      }

    handleChange = (e) =>{
        let fields = this.state.fields;
        fields[e.target.name] = e.target.value;
        this.setState({
            fields
        })

		// console.log(e.target.value)
	}

    validateForm() {
        let phoneError = this.state.phoneError;
		let fields = this.state.fields;
		let errors = {};
		let formIsValid = true;
	
		// First Name error messages
		if (!fields["user_name"]) {
		  formIsValid = false;
		  errors["user_name"] = "*Please enter your First Name.";
        //   this.setState({
        //     stepCount: 1
        //   });
		}
	
		// Email Address error messages
		if (!fields["email"]) {
		  formIsValid = false;
		  errors["email"] = "*Please enter your Email Address";
        //   this.setState({
        //     stepCount: 2
        //   });
		}
	
		if (typeof fields["email"] !== "undefined") {
		  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
		  if (!pattern.test(fields["email"])) {
			formIsValid = false;
			errors["email"] = "*Please enter valid Email Address";
            // this.setState({
            //     stepCount: 2
            // });
		  }
		}
	
        // if (!this.state.phone) {
		// 	formIsValid = false;
		// 	phoneError = "Please enter your phone number.";
        //     this.setState({
        //         stepCount: 3
        //     });
		// }
	
		// Password error messages
		if (!fields["organization"]) {
		  formIsValid = false;
		  errors["organization"] = "*Please enter your message.";
        //   this.setState({
        //     stepCount: 4
        //   });
		}
		
		// Confirm Password error messages
		// if (!fields["description"]) {
		//   formIsValid = false;
		//   errors["description"] = "Please enter your description.";
        //   this.setState({
        //     stepCount: 5
        //   });
		// }
	

	
	
		this.setState({
            phoneError,
		    errors: errors
		});
		return formIsValid;
	}

      handleKeyDown = (e, count) => {
          if (e.key === 'Enter') {
                // if (this.validateForm()) {
                    this.setState({
                        stepCount: count
                    });
                // }

                // if(count === "success"){
                //     this.contactSubmit();
                // }
        }
      }

  render() {
    //   const pageContent = this.props.data;

    return (
        <div className='contact-form contact-form-ads mb-0 py-120' id="contact">
            <div className='container container-md'>
                <div className="row mb-lg-5 mb-4">
                    <div className="col-sm-12 pt-4 text-center">
                        <h1 data-aos="fade-up" data-aos-delay="200">Work with us</h1>
                    </div>
                </div>

                    <form onSubmit={this.contactSubmit}>
                        <div className='contact-field mb-4'>
                            <label htmlFor='userName'>Name*</label>
                            <input id="userName" className='form-control' onChange={this.handleChange} value={this.state.fields.user_name} name='user_name' onKeyDown={(e) => this.handleKeyDown(e, 2)} placeholder='Type your answer here....' type="text" />

                            <div className={`invalid-feedback mt-2 ${!this.state.fields.user_name ? (this.state.errors.user_name ? 'd-block' : null) : null }`}>{this.state.errors.user_name}</div>
                        </div>
                        <div className='contact-field mb-4'>
                            <label htmlFor='email'>Email*</label>
                            <input id="email" className='form-control' onChange={this.handleChange} value={this.state.fields.email} name="email" onKeyDown={(e) => this.handleKeyDown(e, 3)} placeholder='Type your answer here....' type="text" />

                            <div className={`invalid-feedback mt-2 ${!this.state.fields.email ? (this.state.errors.email ? 'd-block' : null) : null }`}>{this.state.errors.email} or valid Email Address.</div>
                        </div>
                        <div className='contact-field mb-4'>
                            <label htmlFor='phone'>Phone</label>
                            {/* <input id="phone" className='form-control' onChange={this.handleChange} name='phone' required={true} onKeyDown={(e) => this.handleKeyDown(e, 4)} placeholder='Type your answer here....' type="text" /> */}
                            <PhoneInput
                                inputExtraProps={{
                                    required: true,
                                    autoFocus: true
                                }}
                                country={'au'}
                                placeholder="Type your answer here...."
                                enableAreaCodeStretch
                                enableAreaCodes={true}
                                value={this.state.phone}
                                onKeyDown={(e) => this.handleKeyDown(e, 4)}
                                dropdownClass="form-shadow form-radius border-0"
                                inputClass="form-control"
                                onChange={(phone) => this.setState({ phone })}
                            />
                            {/* <div className={`invalid-feedback mt-2 ${!this.state.phone ? this.state.phoneError ? "d-block" : null : null}`}>{this.state.phoneError}</div> */}
                        </div>
                        <div className='contact-field mb-4'>
                            <label htmlFor='organization'>Message*</label>
                            <textarea id="organization" className='form-control' onChange={this.handleChange} value={this.state.fields.organization} name='organization' onKeyDown={(e) => this.handleKeyDown(e, 5)} placeholder='Type your answer here....'></textarea>
                            <div className={`invalid-feedback mt-2 ${!this.state.fields.organization ? (this.state.errors.organization ? 'd-block' : null) : null }`}>{this.state.errors.organization}</div>
                        </div>
                        <div className='contact-field mb-4'>
                            <label htmlFor='organization'>Services of Interest</label>
                            {/* <textarea id="organization" className='form-control' onChange={this.handleChange} value={this.state.fields.organization} name='organization' onKeyDown={(e) => this.handleKeyDown(e, 5)} placeholder='Type your answer here....'></textarea> */}
                            <div className="service-checkbox mb-3">
                                <Form.Check
                                    inline
                                    onChange={this.handleServiceSelect}
                                    label="UI/UX Design"
                                    name="group1"
                                    type='checkbox'
                                    value="UI/UX Design"
                                    // checked={state[key]}
                                    id={`inline--1`}
                                />
                                <Form.Check
                                    inline
                                    onChange={this.handleServiceSelect}
                                    label="Web development"
                                    name="group1"
                                    type='checkbox'
                                    value="Web development"
                                    // checked={state[key]}
                                    id={`inline--2`}
                                />
                                <Form.Check
                                    inline
                                    onChange={this.handleServiceSelect}
                                    label="Mobile App Development"
                                    type='checkbox'
                                    value="Mobile App Development"
                                    // checked={state[key]}
                                    id={`inline--3`}
                                />
                                </div>
                            {/* <div className={`invalid-feedback mt-2 ${!this.state.fields.organization ? (this.state.errors.organization ? 'd-block' : null) : null }`}>{this.state.errors.organization}</div> */}
                        </div>
                        <div className='d-flex justify-content-center pt-3'>
                            <button aria-label="Submit" type='submit' className='btn d-inline-flex btn-lg py-sm-2 ps-sm-4 align-items-center btn-control text-uppercase'>
                                <span className='d-inline-flex align-items-center' style={{minHeight: '30px'}}>
                                    {this.state.loading ? 'Loading...' : 'Submit'} 

                                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi ms-2 bi-arrow-right-short" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                                    </svg>
                                </span>
                            </button>
                        </div>
                    </form>
                    {this.state.success && (
                        <div className='contact-form-thankyou pt-5 text-center'>
                            <h3 className='mb-4'>Thank you for contacting Alkye Services. <br />We will get in touch with you soon.</h3>
                        </div>
                    )}
            </div>
        </div>


    )
  }
}
