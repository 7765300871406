import React from "react";
import { Link } from "gatsby"
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

const ToolsSlider = ({posts}) => {
    const [position, setPosition] = React.useState({ x: 0, y: 0 });

    React.useEffect(() => {
        const setFromEvent = (e) => setPosition({ x: e.clientX, y: e.clientY });
        window.addEventListener("mousemove", setFromEvent);
      
        return () => {
            window.removeEventListener("mousemove", setFromEvent);
        };
    }, []);

    // const position = useMousePosition();
    // console.log(position)
  return (
    <div className="tools-outer">
        <div className="drag-cursor text-center" style={{left : position.x, top: position.y}}><span>DRAG <br />-- & --<br /> VIEW</span></div>
        <div className="container">
            <Swiper
                spaceBetween={30}
                grabCursor={true}
                centeredSlides={posts?.length < 4 ? true : false}
                breakpoints = {{
                    0: {
                        slidesPerView: "auto",
                        spaceBetween: 10
                    },
                    576: {
                        slidesPerView: 3
                    },
                    992: {
                        slidesPerView: 4
                    },
                    1440: {
                        slidesPerView: 5
                    }
                }}
                className="tools"
            >
                {posts && posts.map((item, index) => (
                    item.logoImage && 
                    <SwiperSlide key={item.index}>
                        {/* <Link aria-label="Tools" to={'/tool/' + item.slug}> */}
                            <div className="tool-circle">
                                <img loading="lazy" width="160" height="160" src={item.logoImage && item.logoImage?.mediaItemUrl} alt={item.logoImage && item.logoImage?.altText} className="gatsby-image-wrapper" />
                            </div>
                        {/* </Link> */}
                    </SwiperSlide>
                ))}                
            </Swiper>
        </div>
    </div>
  );
}

export default ToolsSlider;