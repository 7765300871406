import React, { useEffect, useState } from 'react';

const HomeGrowth = ({pageContent}) => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > window.innerHeight / 4)
    })
  }, [])

  return(
      <div className="section-growth position-relative text-white py-120">
          <div className="section-growth-overlay" style={{ width: scroll ? '100%' : '96%' }}></div>
          <div className="container">
              <div className="row mb-5 pb-md-4">
              <div className="col-sm-10 col-md-8 mb-4 col-xxl-9 col-lg-9"  data-aos="fade-up" data-aos-delay="200">
                  <h2 className='h1'><span  dangerouslySetInnerHTML={{__html: pageContent?.awardSectionText}} /></h2>
                  {/* <h2 className='h1'><span>
                    We help <strong><em>you</em></strong> create <strong><em>digital experiences</em></strong> that advance your vision for a <strong><em>brighter future</em></strong></span>
                  </h2> */}
              </div>
              </div>

              <div className="row text-center align-items-center" data-aos="fade-up" data-aos-delay="200">
                  {pageContent?.awardLogos?.map((logo, index) => (
                      <div className="col-6 col-sm-3 mb-sm-0 mb-3 col-md-3 col-lg-2" key={index}>
                          <img loading="lazy" width="158" height="145" src={logo.logoImage?.mediaItemUrl} className="gatsby-image-wrapper img-fluid" alt={logo.logoImage?.altText} />
                      </div>
                  ))}
              </div>
          </div>
      </div>
  )
};

export default HomeGrowth;