import React from 'react'
import { StaticQuery, graphql, Link } from "gatsby"
import SocialSite from '../socialSite';

const HeaderQuery = ({barLine}) => (
    <StaticQuery
        query={graphql`
          {
            allWpPage(filter: {slug: {eq: "global-page"}}) {
              nodes {
                globalPage {
                  socialsHeader {
                    name
                    url
                  }
                  officeLocations {
                    officeLocation
                  }
                }
              }
            }
            wpMenu(slug: {eq: "main-menu"}) {
              menuItems {
                nodes {
                  id
                  label
                  path
                }
              }
            }
          }
        `}
        render={data => {
          const pageContent = data.allWpPage.nodes[0].globalPage;
          const pageMenu = data.wpMenu.menuItems.nodes;

          
          const handle = () => barLine();

          return(
            <div className="header-overlay">
              <div className="row pb-3 w-100 gx-0 justify-content-between">
                <div className="col-md-7 mb-md-0 mb-4">
                  <ul className="main-menu title fade-in">
                  {pageMenu && pageMenu.map((item) => (
                    <li key={item.label}><Link aria-label={item.label} onClick={() => handle()} to={item.path}>{item.label}</Link></li>
                  ))}
                  {/* <li><Link onClick={() => handle()} to="/register#home">Home</Link></li>
                  <li><Link onClick={() => handle()} to="/register#services">Services</Link></li>
                  <li><Link onClick={() => handle()} to="/register#partner">Partner</Link></li>
                  <li><Link onClick={() => handle()} to="/register#faq">FAQ’s</Link></li> */}
                </ul>

                  <div className="header-social fade-in fade-in-500 d-flex align-items-center justify-content-between">
                    {/* {pageContent?.socialsHeader && pageContent?.socialsHeader.map((social, index) => (
                      <Link target="_blank" to={social.url} key={index}>{social.name}</Link>
                    ))} */}
                    <SocialSite socialLinks={pageContent?.socialsHeader} />
                  </div>
                </div>
                <div className="col-md-4 header-sidenav">
                  <div className="row">
                    {/* <div className="col-6 col-md-12">
                      <ul className="list-unstyled h4 mb-md-5 mb-4 pb-2 fade-in fade-in-200">
                        {pageMenu && pageMenu.map((item) => (
                          <li key={item.label}><Link onClick={() => handle()} to={item.path}>{item.label}</Link></li>
                        ))}
                        <li><Link onClick={() => handle()} to="/contact">Contact</Link></li>
                      </ul>
                    </div> */}
                    <div className="col-md-12">
                      {/* <address className="mb-md-5 d-lg-block address-desktop d-none mb-4 pb-2 h5 fade-in fade-in-300" dangerouslySetInnerHTML={{__html: pageContent?.content1}} />
                      <address className="mb-md-5 d-lg-none mb-4 pb-2 h5 fade-in fade-in-300" dangerouslySetInnerHTML={{__html: pageContent?.content1}} />
  
                      <address className="mb-md-5 d-lg-block address-desktop d-none mb-4 pb-2 h5 fade-in fade-in-400" dangerouslySetInnerHTML={{__html: pageContent?.content2}} />   
                      <address className="mb-md-5 d-lg-none mb-4 pb-2 h5 fade-in fade-in-400" dangerouslySetInnerHTML={{__html: pageContent?.content2}} />    */}
                      {pageContent?.officeLocations?.map((address, index) => (
                        <address key={index} className='location-adr fade-in fade-in-300' dangerouslySetInnerHTML={{__html: address.officeLocation }} />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }}></StaticQuery>
  )

  export default HeaderQuery;