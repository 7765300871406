import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import { graphql } from "gatsby"
import ContactForm from '../components/ads/contactForm'
import FAQ from '../components/ads/faq'
import HomeGrowth from '../components/ads/homeGrowth'
import HomeServices from '../components/ads/homeServices'
import Layout from '../components/ads/layout-ads'
import ToolsSlider from '../components/ads/toolsSlider'

const Register = ({data}) => {
    const pageContent = data.allWpPage.nodes[0]?.adLandingPage;
    const [scroll, setScroll] = useState(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
        setScroll(window.scrollY > window.innerHeight / 10)
        })
    }, [])
    // console.log(pageContent);
  return (
      <Layout>
        <div className="page-intro text-center">
            <div className="container">
                <div className="row justify-content-center">
                <div className="col-md-10 col-lg-7 col-xl-8 col-xxl-9">
                    <h1 className="title"><span data-aos="fade-in" data-aos-delay="400">{pageContent?.ideateText}.</span> <em data-aos="fade-in" data-aos-delay="800">{pageContent?.createText}.</em> <span data-aos="fade-in" data-aos-delay="1200">{pageContent?.growText}.</span></h1>
                    {/* <h1 className="title" dangerouslySetInnerHTML={{__html: pageContent?.bannerHeading}} /> */}
                    <div data-aos="fade-in" data-aos-delay="1600">
                        <p>{pageContent?.bannerSubheading}</p>

                        <Link to="/register#contact" className={`btn d-inline-flex btn-lg py-sm-2 ps-sm-4 align-items-center btn-control text-uppercase ${scroll ? 'active' : ''}`}>
                            {pageContent?.bannerButtonText} 
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi ms-3 bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                            </svg>
                        </Link>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <HomeGrowth pageContent={pageContent && pageContent} />

        <HomeServices pageContent={pageContent && pageContent} />

        <div className='bg-primary text-center py-120'>
            <div className='container container-md'>
                <div className='row justify-content-between flex-column align-items-center' data-aos="fade-up" data-aos-delay="200">
                    <div className='col-lg-5 col-xl-6 mb-4'>
                        <h2 className='h1' dangerouslySetInnerHTML={{__html: pageContent?.ctaBannerText}} />
                    </div>
                    <div className='col-md-4 text-center'>
                        <Link to="/register#contact" className='btn d-inline-flex btn-lg py-sm-2 ps-sm-4 btn-white align-items-center btn-control text-uppercase'>
                            {pageContent?.ctaBannerButton} 
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi ms-3 bi-arrow-right-short" viewBox="0 0 16 16">
                                <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                            </svg>
                        </Link>
                    </div>
                </div>
            </div>
        </div>

        <ContactForm />


        <div className="py-120 overflow-hidden" id="partner">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-md-12 pt-4">
                        <h2 className='h1'  data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: pageContent?.partnershipSectionTitle}} />
                        <p  data-aos="fade-up" data-aos-delay="400">{pageContent?.partnershipSectionSubtitle}</p>
                    </div>
                </div>
            </div>

            <ToolsSlider posts={pageContent?.partnershipSlider && pageContent?.partnershipSlider} />
        </div>


        <div className="py-120 overflow-hidden" id="faq">
            <div className="container">
                <div className="row mb-5">
                    <div className="col-md-12 pt-4">
                        <h2 className='h1'  data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: pageContent?.faqTitle}} />
                        <p  data-aos="fade-up" data-aos-delay="400">{pageContent?.faqText}</p>
                    </div>
                </div>
            </div>

            <div className="container container-md" data-aos="fade-up" data-aos-delay="200">
                <FAQ pageContent={pageContent?.faqs && pageContent?.faqs} />
            </div>

            {/* <ToolsSlider /> */}
        </div>

      </Layout>
  )
}
export default Register

export const query = graphql`{
    allWpPage(filter: {slug: {eq: "ad-landing-page"}}) {
        nodes {
            adLandingPage {
              ideateText
              createText
              growText
              bannerSubheading
              bannerButtonText
              awardSectionText
              awardLogos {
                logoImage {
                  altText
                  mediaItemUrl
                }
              }
              serviceSectionSubTitle
              serviceSectionText
              adPageServices {
                heading
                startingFrom
                serviceText
                buttonText
                image {
                  altText
                  mediaItemUrl
                }
              }
              ctaBannerText
              ctaBannerButton
              partnershipSectionTitle
              partnershipSectionSubtitle
              partnershipSlider {
                logoImage {
                  altText
                  mediaItemUrl
                }
              }
              faqTitle
              faqText
              faqs {
                question
                answer
              }
            }
        }
      }
  }`